import React, { useEffect, useState } from 'react';
import '../styles/DarkMode.css'; // Assuming DarkMode.css handles the theme classes

const DarkMode: React.FC = () => {
  // Get initial theme (light or dark) based on system preferences or localStorage
  const getInitialTheme = (): 'light' | 'dark' => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      return savedTheme as 'light' | 'dark';
    } else {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      return prefersDark ? 'dark' : 'light';
    }
  };

  // Set theme state and manage it across page loads
  const [theme, setTheme] = useState<'light' | 'dark'>(getInitialTheme);

  useEffect(() => {
    // Apply the theme class to the html element
    document.documentElement.className = theme;
    localStorage.setItem('theme', theme); // Store the selected theme
  }, [theme]);

  // Function to toggle between light and dark modes
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return (
    <div className="dark-mode-toggle">
      <button onClick={toggleTheme}>
        {theme === 'light' ? 'Dark' : 'Light'} Mode
      </button>
    </div>
  );
};

export default DarkMode;

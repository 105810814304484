import React from 'react';
import '../styles/Portfolio.css';

interface Project {
  id: number;
  title: string;
  description: string;
  imageUrl?: string;
  link?: string;
}

interface PortfolioItemProps {
  project: Project;
}

const PortfolioItem: React.FC<PortfolioItemProps> = ({ project }) => {

  return (
    <div className="portfolio-item">
      {project.link ? (
        <a href={project.link} target="_blank" rel="noopener noreferrer">
          {project.imageUrl && (
            <img src={project.imageUrl} alt={project.title} loading="lazy" />
          )}
          <h3>{project.title}</h3>
        </a>
      ) : (
        <div>
          {project.imageUrl && (
            <img src={project.imageUrl} alt={project.title} />
          )}
          <h3>{project.title}</h3>
        </div>
      )}
    </div>
  );
};

export default PortfolioItem;
